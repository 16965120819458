import { useEffect, useRef, useState } from 'react';
import ColorPickerWrapper from './ColorPickerWrapper';
import { DEFAULT_RGBA_COLORS } from './constants';
import { Fab, Popover } from '@mui/material';
import { RGBA } from './types';
import rgbaToHex from './util/rgbaToHex';

export type ColorResult = string | RGBA;

interface ColorPickerProps {
  handleColorChange: (color: ColorResult) => void;
  currentColor?: ColorResult;
  customRef?: React.RefObject<HTMLElement>;
}

interface ColorPopupProps {
  onClose: (color: ColorResult) => void;
  open: boolean;
  useRgba?: boolean;
  buttonRef?: React.RefObject<HTMLElement>;
  currentColor: ColorResult;
}

function ColorPopup({ onClose, open, buttonRef, currentColor }: ColorPopupProps) {
  const [color, setColor] = useState<ColorResult>(currentColor || DEFAULT_RGBA_COLORS);
  const defaultWidthInPx = 250;

  useEffect(() => {
    if (currentColor) {
      setColor(currentColor);
    }
  }, [currentColor]);

  const handleClose = () => {
    onClose(color);
  };

  const colorChangeHandler = (color: ColorResult) => {
    if (typeof color === 'string') {
      setColor(color);
      return;
    } else {
      const { r, g, b, a } = color as RGBA;
      const updatedHex = rgbaToHex(r, g, b, a);
      setColor(updatedHex.slice(1));
    }
  };

  return (
    <Popover
      disableScrollLock={false}
      onClose={handleClose}
      open={open}
      anchorEl={buttonRef?.current}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div className="color-picker-container">
        <ColorPickerWrapper color={color} width={defaultWidthInPx} onChange={(color) => colorChangeHandler(color.rgba)} />
      </div>
    </Popover>
  );
}

export default function ColorPicker({ handleColorChange, customRef, currentColor }: ColorPickerProps) {
  const [open, setOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState<ColorResult>(currentColor || DEFAULT_RGBA_COLORS);
  const fabRef = useRef(null);

  const convertToHexa = (color: RGBA) => {
    return rgbaToHex(color.r, color.g, color.b, color.a);
  };

  useEffect(() => {
    if (currentColor) {
      if (typeof currentColor === 'string') {
        setSelectedColor(currentColor);
      } else {
        setSelectedColor(convertToHexa(currentColor));
      }
    }
  }, [currentColor]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (currentColor: ColorResult) => {
    setOpen(false);
    if (typeof selectedColor === 'string') {
      setSelectedColor(currentColor);
      handleColorChange(currentColor);
    } else {
      const { r, g, b, a } = currentColor as RGBA;
      setSelectedColor(rgbaToHex(r, g, b, a));
      handleColorChange(rgbaToHex(r, g, b, a));
    }
  };
  const backgroundColor =
    typeof selectedColor === 'string' ? selectedColor : rgbaToHex(selectedColor?.r, selectedColor?.g, selectedColor?.b, selectedColor?.a);
  const convertedColor = currentColor && typeof currentColor === 'string' ? currentColor : DEFAULT_RGBA_COLORS;

  const buttonStyle = {
    width: '32px',
    height: '32px',
    minHeight: '32px',
    cursor: 'pointer',
    transition: 'all 0.3s',
    '&:hover': {
      transform: 'scale(1.1)',
      filter: 'brightness(95%)',
    },
  };

  return (
    <>
      <Fab sx={[buttonStyle]} ref={fabRef} style={{ backgroundColor: backgroundColor }} onClick={handleClickOpen}></Fab>
      <ColorPopup open={open} onClose={handleClose} buttonRef={customRef || fabRef} currentColor={convertedColor} />
    </>
  );
}
