import * as React from 'react';
const SvgIcoColorPicker = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    strokeWidth={0}
    {...props}
  >
    <path
      d="M20.385 2.879a3 3 0 0 0-4.243 0L14.02 5l-.707-.708a1 1 0 1 0-1.414 1.415l5.657 5.656A1 1 0 0 0 18.97 9.95l-.707-.707 2.122-2.122a3 3 0 0 0 0-4.242"
      fill="currentColor"
      stroke="none"
    />
    <path
      d="M11.93 7.091 4.152 14.87a3 3 0 0 0-.587 3.415L2 19.85l1.414 1.415 1.565-1.566a3 3 0 0 0 3.415-.586l7.778-7.778zm1.414 4.243L11.93 9.92l-6.364 6.364a1 1 0 0 0 1.414 1.414z"
      fill="currentColor"
      fillRule="evenodd"
      stroke="none"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIcoColorPicker;
