import { FC } from 'react';
import { colorParams, HSVA, RGBA } from '../types';
import { ColorModel } from '../colorModel';

import './style.less';

interface ColorPresetInterface {
  onChange: (hsva: HSVA) => void;
  presetColors: colorParams[];
}

export const ColorPreset: FC<ColorPresetInterface> = (props) => {
  const { onChange, presetColors } = props;

  const handleSelectColor = (color: RGBA) => {
    onChange(ColorModel.toColorSet(color).hsv);
  };

  return (
    <div className="preset-container">
      {' '}
      {}
      {presetColors.map((color) => {
        const { r, g, b, a } = ColorModel.toColorSet(color).rgba;
        return (
          <div
            key={color.toString()}
            style={{ backgroundColor: `rgba(${r}, ${g}, ${b}, ${a})` }}
            className="preset-cell"
            onMouseDown={() => handleSelectColor({ r, g, b, a })}
          ></div>
        );
      })}
    </div>
  );
};
