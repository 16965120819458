import ColorPicker, { ColorResult } from 'components/editor/shared/components/ColorPicker';
import { Grid } from '@mui/material';
import { SecondaryMenuItemTitle } from './SecondaryMenuTitles';
import { useTranslation } from 'react-i18next';

type ColorPickerSectionProps = {
  currentColor?: string;
  handleColorChange: (color: ColorResult) => void;
};

export const ColorPickerSection = ({ currentColor = '#fff', handleColorChange }: ColorPickerSectionProps) => {
  const { t } = useTranslation();
  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <SecondaryMenuItemTitle>{t('editor.side_panel.color')}</SecondaryMenuItemTitle>
      <Grid item>
        <ColorPicker currentColor={currentColor} handleColorChange={handleColorChange} />
      </Grid>
    </Grid>
  );
};
