import tinycolor from 'tinycolor2';
import { ColorEnum, colorParams, ColorSet } from './types';

export class ColorModel {
  static toColorSet(data: colorParams): ColorSet {
    const color = tinycolor(data);
    const alpha = color.getAlpha();
    color.setAlpha(alpha);

    const isHex8 = color.getFormat() === 'hex8' || alpha < 1;

    const rgb = color.toRgb();
    const hex = isHex8 ? color.toHex8() : color.toHex();
    const hsv = color.toHsv();
    const hsl = color.toHsl();

    return {
      hex,
      hsv: {
        ...hsv,
        s: hsv.s * 100,
        v: hsv.v * 100,
      },
      hsl: {
        ...hsl,
        s: hsl.s * 100,
        l: hsl.l * 100,
      },
      rgba: rgb,
    };
  }

  static isColorEqual(source: colorParams, target: colorParams) {
    const targetSrc = tinycolor(target).toString();
    const sourceColor = tinycolor(source).toString();
    return targetSrc === sourceColor;
  }

  static getType(color: colorParams): ColorEnum | null {
    const str = tinycolor(color).toString();

    if (str.indexOf('#') !== -1) return ColorEnum.HEX;
    else if (str.indexOf('rgb') !== -1) return ColorEnum.RGBA;
    else if (str.indexOf('hsv') !== -1) return ColorEnum.HSV;
    else if (str.indexOf('hsl') !== -1) return ColorEnum.HSL;
    return null;
  }
}
