import { FC } from 'react';
import { Saturation } from '../Saturation';
import { colorParams, ColorSet } from '../types';
import { Hue } from '../Hue';
import { Transparent } from '../Transparency';
import { ColorPreview } from '../ColorPreview';
import { HexInput } from '../colorInputs/HexInput';
import { useColorManipulate } from '../hooks/useColorManipulate';
import { COLOR, DEFAULT_PRESET_COLORS, WIDTH } from '../constants';
import { RgbaInput } from '../colorInputs/RgbaInput';
import { ColorPreset } from '../ColorPreset';
import { Dropper } from '../Dropper';

import './style.less';

interface ColorPickerInterface {
  width?: number | string;
  color?: colorParams;
  onChange?: (color: ColorSet) => void;
  hideEyeDrop?: boolean;
  hidePresets?: boolean;
  presetColors?: colorParams[];
}

const ColorPickerWrapper: FC<ColorPickerInterface> = (props) => {
  const { color = COLOR, width = WIDTH, onChange, hideEyeDrop = false, hidePresets = false, presetColors = DEFAULT_PRESET_COLORS } = props;
  const { hsva, handleChangeColor } = useColorManipulate(color, onChange);

  return (
    <div className="wrapper-container" style={{ width }}>
      <Saturation hsv={hsva} onChange={handleChangeColor}></Saturation>

      <div className="toolboxContainer">
        <ColorPreview hsv={hsva}></ColorPreview>
        <div className="toolboxSelector">
          <Hue hsva={hsva} onChange={handleChangeColor}></Hue>
          <Transparent hsv={hsva} onChange={handleChangeColor}></Transparent>
        </div>
      </div>

      <div className="colorInput">
        <HexInput hsva={hsva} onChange={handleChangeColor}></HexInput>
        <RgbaInput hsva={hsva} onChange={handleChangeColor}></RgbaInput>
        {!hideEyeDrop && <Dropper onChange={handleChangeColor}></Dropper>}
      </div>

      {!hidePresets && <ColorPreset presetColors={presetColors} onChange={handleChangeColor}></ColorPreset>}
    </div>
  );
};

export default ColorPickerWrapper;
