export default function rgbaToHex(red, green, blue, alpha): string {
  // Convert each decimal component to hexadecimal
  const redHex = red.toString(16).padStart(2, '0');
  const greenHex = green.toString(16).padStart(2, '0');
  const blueHex = blue.toString(16).padStart(2, '0');
  const alphaHex = Math.round(alpha * 255)
    .toString(16)
    .padStart(2, '0'); // Convert alpha to 0-255 range and then to hexadecimal

  return '#' + redHex + greenHex + blueHex + alphaHex;
}
