import { FC } from 'react';
import { ColorModel } from '../colorModel';
import { HSVA } from '../types';

import './style.less';

interface ColorPreviewInterface {
  hsv: HSVA;
}

export const ColorPreview: FC<ColorPreviewInterface> = (props) => {
  const { hsv } = props;
  const { r, g, b, a } = ColorModel.toColorSet(hsv).rgba;

  return (
    <div className="preview-container">
      <div
        style={{
          backgroundColor: `rgba(${r}, ${g}, ${b}, ${a})`,
        }}
      ></div>
    </div>
  );
};
