import { FC, useEffect } from 'react';
import { Icons } from '../../../../../icon/icons';
import { useCustomEyeDropper } from './hooks/useCustomEyeDropper';
import { isBrowserEyeDropper } from '../util/isBrowserSupportEyeDropper';
import { HSVA } from '../types';
import { ColorModel } from '../colorModel';

import './style.less';

const { IcoColorPicker } = Icons;

interface DropperInterface {
  onChange: (hsva: HSVA) => void;
}

export const Dropper: FC<DropperInterface> = (props) => {
  const { onChange } = props;
  const { openDropper, color, closeDropper } = useCustomEyeDropper();

  const handleOpenDropper = async () => {
    if (isBrowserEyeDropper()) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const eyeDropper = new EyeDropper();
      eyeDropper
        .open()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .then((res) => onChange(ColorModel.toColorSet(res.sRGBHex).hsv));
    } else {
      openDropper();
    }
  };

  useEffect(() => {
    if (!color) return;
    const hex = ColorModel.toColorSet(color).hex;
    closeDropper();
    onChange(ColorModel.toColorSet(hex).hsv);
  }, [color]);

  return (
    <button onClick={handleOpenDropper} className="dropper-button">
      <IcoColorPicker />
    </button>
  );
};
